import * as React from 'react'
import Layout from '../../components/layout'
import {  Link } from 'gatsby'
import '../../styles/global.css' 
import BlockPic1 from '../../../static/img/projects/banana-ring-purple.webp'
import Instagram from '../../../static/img/team/instagram.png'
import BlockPic2 from '../../../static/img/projects/banana-gid1.webp'
import BlockPic3 from '../../../static/img/projects/banana-grid2.webp'

import Fade from 'react-reveal/Fade' // Importing Fade effect


import {Helmet} from "react-helmet";

import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';



export default function BananaBlock() {
  return (
    <Layout pageTitle="Banana Block">
      
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Banana Block - USI Projects</title>
        <meta name="description" content="AMTCE supports people in reskilling or upskilling in new technologies."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

      <section class="text-gray-700 body-font heading-container-banana">
        <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Banana Block</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                 Tourism NI Fund</p>
              </div>
          </section>
        </div>
      </section>
      
      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-6 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-16 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-yellow-300">OVERVIEW</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                As part of a sustainable master plan to regenerate the Portview site, we secured a capital grant
                to develop a pop-up banana museum onsite. Having partnered with National Museums NI, this initiative 
                will permit the testing of bold ideas to inform the site’s long term museum offer. By reframing the 
                response “it’s not for me”, the project challenges normative understandings of ‘the museum’ and seeks 
                to reframe its ethos and organisation through a novel philosophy that will engage with new audiences 
                from a range of backgrounds. This will provide a truly innovative, authentic, and unique museum experience 
                that will add to the richness of National Museums NI’s existing portfolio.</p>
              </div>
              <div class="md:w-1/4 py-4 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Communities<br/>
                  Public Space<br/>
                  Culture<br/>
                  Regeneration<br/>
                  Tourism<br/>
                  Economy<br/></p>
                <p class="text-2xl font-bold uppercase mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Engagement<br/>Architecture<br/>Culture<br/>Programming<br/>Tourism<br/>Events Management</p>
                <p class="text-2xl font-bold uppercase mt-8">Visit</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0"><a target="_blank" href='https://www.bananablock.org'>bananablock.org</a></p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:p-16 md:m-16">
        <video controls src="https://player.vimeo.com/external/543597702.hd.mp4?s=173201085287294c8a3dbdad34bf61033ade6faf&profile_id=175"/>
      </div>

      <div class="px-5 md:px-16 py-6 md:py-16 md:m-16">
        <h3 class="text-6xl font-heading text-yellow-300">Why Bananas?</h3>
        <p class="my-4 text-xl md:text-2xl lg:text-3xl ">Why Bananas you ask? And what’s an old spinning mill steeped in Belfast’s rich linen heritage got to do with this? Well, in 1911, 
        while the Titanic was preparing to set sail, East Belfast resident William Richardson, who worked as the head gardener for the 
        orthogonal mill owner, became one of the first people in the British Isles to grow ripe edible  bananas - yes, in East&nbsp;Belfast! <br/><br/>
        Amid political unrest and conflict across the province, the banana then emerged as a symbol of unity 21 years later when Catholic
         and Protestant workers came together in 1932 to protest the unfair working conditions of the city’s mills. Marching along to Louis 
         Prima’s “Yes We Have No Bananas”, the banana acts as a conduit for Belfast’s industrial heritage and reclaims a positive memory that 
        demonstrates a collective mindset of how diversity and inclusion can be achieved even during the worst of times. 
        </p>
      </div>

      <div className="px-5 md:px-16 m-auto w-full">
       <ReactCompareSlider
        itemOne={<ReactCompareSliderImage src={BlockPic1} srcSet={BlockPic1} alt="Concept" className=''/>}
        itemTwo={<ReactCompareSliderImage src="https://ik.imagekit.io/5y988hvt8sd/USI/banana-block-camera-shot_lXWwNkne7GQ.png?updatedAt=1639047438458" srcSet="https://ik.imagekit.io/5y988hvt8sd/USI/banana-block-camera-shot_lXWwNkne7GQ.png?updatedAt=1639047438458" alt="Delivery" />}
       />
       <div className="py-2 ">
         <p>From January 2021 to September 2021, from concept to delivery for Banana Block at Portview.</p>
       </div>
      </div>

      <div class="px-5 md:px-16 py-6 md:py-16 md:m-16">
        <h3 class="text-6xl font-heading text-yellow-300">A Living Museum</h3>
        <p class="my-4 text-xl md:text-2xl lg:text-3xl ">
        Banana Block is  a ‘living museum’ with an active focus on creating the future, not re-creating the past. 
        It will be alive through the beating pulse of people getting involved, connecting and creating, with heritage and 
        with each other. It will be alive because it never stays still, always reinventing its impact through co-created ideas 
        and dynamic partnerships, placing heritage, sustainability and creativity and the centre of the community.<br/><br/>
        Featuring pop-up exhibitions, a flexible event space, an indoor ring of exotic plants (including banana trees) 
        and a zero-waste cafe and onsite tenant makers and producers, Banana Block will redefine what a museum looks like 
        and turn the viewers focus to the future.
        </p>
      </div>

      <div className="px-5 md:px-16 py-6 md:py-16 md:m-16 grid md:grid-cols-2 gap-6 mx-auto">
      <Fade left>
        <img className="py-2 w-full mx-auto" src={BlockPic2} alt=""/>
      </Fade>
      <Fade right>
        <img className="py-2 w-full mx-auto" src={BlockPic3} alt=""/>
      </Fade>
      </div>

      <div class="text-center items-center md:max-w-full h-3/5 bg-yellow-300 pb-12">
        <h1 class="text-2xl md:text-3xl lg:text-6xl py-6 font-banana">Join the bunch<br/>bananablock.org</h1>
        <a class="py-6" href="https://www.instagram.com/bananablockofficial/" target="_blank" rel="noreferrer"><img className="m-auto h-32" src={Instagram} alt="instagram" /></a>
      </div>


      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-yellow-300">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../portview" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>


    </Layout>
  )
}
